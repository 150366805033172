<template>
  <CRow>
    <CCol>
      <div v-if="serverErrors.length">
        <CAlert v-model="showDismissibleAlert" color="warning" closeButton>
          <ul>
            <li v-for="err in serverErrors" :key="err">{{ err }}</li>
          </ul>
        </CAlert>
      </div>
      <form v-on:submit.prevent="onSubmitted">
        <div class="row">         
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>Reasons of rejection</label>
              <textarea class="form-control" v-model="newApplication.rejected_reason" required></textarea>              
            </div>
          </div>         
        </div>

        <button type="submit" class="btn btn-primary">Reject</button>
      </form>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: "RejectModal",
  data: () => {
    return {      
      newApplication: {
        status: "REJECTED",        
        rejected_reason: "",        
      },
      serverErrors: [],
      showDismissibleAlert: false,
    };
  },

  methods: {
    onSubmitted() {     
      this.newApplication.id = this.$route.params.id;

      this.$store
        .dispatch("CustomerApplication/verifyApplication", this.newApplication)
        .then(() => {
          this.showToast = true;
          this.$toastr.s(
                    "Customer's application successfuly rejected.",
                    "Rejected!"
                  );
          setTimeout(() => {
            this.$emit("onApplicationRejection");
          }, 500);
        })
        .catch((err) => {
          this.showDismissibleAlert = true;
          this.serverErrors = [];
          if (err.response.data.message) {
            this.serverErrors.push(err.response.data.message);
          }          
        });
    },
  },
};
</script>
