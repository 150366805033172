var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"printArea"}},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-md-2 text-right d-print-none"},[(
          _vm.checkUsrPermissions(['CApp_all', 'CApp_edit']) &&
            _vm.application.status !== 'APPROVED'
        )?_c('a',{staticClass:"btn btn-primary mr-4",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.$router.push('/customer-applications/' + _vm.application.id + '/edit')}}},[_c('i',{staticClass:"fa fa-pencil",attrs:{"aria-hidden":"true"}}),_vm._v(" Edit ")]):_vm._e(),_c('a',{staticClass:"print-btn",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.print()}}},[_c('i',{staticClass:"fa fa-print",attrs:{"aria-hidden":"true"}}),_vm._v(" Print")])])]),_c('CRow',[_c('CCol',{staticClass:"pr-0",attrs:{"sm":"2"}},[_c('CCard',[_c('CRow',[_c('CCol',{staticClass:"avatar-container"},[_c('img',{staticClass:"mb-2",attrs:{"name":"cil-user","src":_vm.application.photo_url
                  ? _vm.application.photo_url
                  : _vm.getImgUrl('avatar_placeholder.png'),"alt":"User Logo"}})])],1)],1)],1),_c('CCol',{attrs:{"sm":"10"}},[_c('CCard',[_c('CRow',[_c('CCol',{staticClass:"text-right d-print-none",attrs:{"sm":"12"}}),_c('CCol',{attrs:{"sm":"12"}},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-borderless appl-view-tbl"},[_c('thead',[_c('tr',[_c('th',{attrs:{"colspan":"6"}},[_vm._v("Primary Information")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Full Name:")]),_c('td',[_vm._v(" "+_vm._s(_vm.application.salutation + " " + _vm.application.first_name + " " + _vm.application.last_name)+" ")]),_c('td',[_vm._v("Father's Name:")]),_c('td',[_vm._v(_vm._s(_vm.application.father_name))]),_c('td',[_vm._v("Mother's Name:")]),_c('td',[_vm._v(_vm._s(_vm.application.mother_name))])]),_c('tr',[_c('td',[_vm._v("Spouse Name:")]),_c('td',[_vm._v(_vm._s(_vm.application.spouse_name))]),_c('td',[_vm._v("Mobile No:")]),_c('td',[_vm._v(_vm._s(_vm.application.mobile_number))]),_c('td',[_vm._v("Email:")]),_c('td',[_vm._v(_vm._s(_vm.application.email))])]),_c('tr',[_c('td',[_vm._v("Category:")]),_c('td',[_vm._v(_vm._s(_vm.application.category))]),_c('td',[_vm._v("Connection Type:")]),_c('td',[_vm._v(_vm._s(_vm.application.connection_type))]),_c('td',[_vm._v("Connection Durability:")]),_c('td',[_vm._v(_vm._s(_vm.application.connection_duration))])]),_c('tr',[_c('td',[_vm._v("Has any Connection?")]),_c('td',[_vm._v(" "+_vm._s(_vm.application.has_previous_connection ? "Yes" : "No")+" ")]),_c('td',[_vm._v("Created At")]),_c('td',[_vm._v(_vm._s(_vm.dateFormat(_vm.application.created_at)))]),_c('td',[_vm._v("Account No")]),_c('td',[_vm._v(" "+_vm._s(_vm.application.account_number)+" ")])]),_c('tr',[_c('td',[_vm._v("Status")]),_c('td',[_c('span',{staticClass:"badge",class:_vm.application.status === 'APPROVED'
                            ? 'badge-success'
                            : _vm.application.status === 'REJECTED'
                            ? 'badge-danger'
                            : 'badge-primary'},[_vm._v(_vm._s(_vm.application.status))])]),(_vm.application.status === 'REJECTED')?_c('td',[_vm._v(" Reasons of Rejection ")]):_vm._e(),(_vm.application.status === 'REJECTED')?_c('td',[_vm._v(" "+_vm._s(_vm.application.rejected_reason)+" ")]):_vm._e()])])]),_c('table',{staticClass:"table table-borderless appl-view-tbl"},[_c('thead',[_c('tr',[_c('th',{attrs:{"colspan":"6"}},[_vm._v("Address Details")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Address:")]),_c('td',[_vm._v(_vm._s(_vm.application.address))]),_c('td',[_vm._v("Area:")]),_c('td',[_vm._v(_vm._s(_vm.application.area))]),_c('td',[_vm._v("Ward No:")]),_c('td',[_vm._v(_vm._s(_vm.application.ward_number))])]),_c('tr',[_c('td',[_vm._v("City:")]),_c('td',[_vm._v(_vm._s(_vm.application.city))]),_c('td',[_vm._v("Division:")]),_c('td',[_vm._v(_vm._s(_vm.application.division))]),_c('td',[_vm._v("Country:")]),_c('td',[_vm._v(_vm._s(_vm.application.country))])])])]),(
                  _vm.application.related_fees && _vm.application.related_fees.length
                )?_c('table',{staticClass:"table table-borderless appl-view-tbl",staticStyle:{"max-width":"45%","margin-right":"10%","float":"left"}},[_c('thead',[_c('tr',[_c('th',{attrs:{"colspan":"6"}},[_vm._v("Connection/Other Fees")])])]),_c('tbody',[_c('tr',[_c('th',[_vm._v("Item Name")]),_c('th',{staticClass:"text-center"},[_vm._v("Fees")])]),_vm._l((_vm.application.related_fees),function(val,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(val.item))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(val.fee))])])}),_c('tr',{staticStyle:{"border-top":"1px solid #ccc"}},[_c('th',{staticClass:"text-right"},[_vm._v("Total")]),_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.calculateTotal()))])])],2)]):_vm._e(),(
                  _vm.application.attachments && _vm.application.attachments.length
                )?_c('table',{staticClass:"table table-borderless appl-view-tbl",staticStyle:{"max-width":"45%","float":"left"}},[_c('thead',[_c('tr',[_c('th',{attrs:{"colspan":"6"}},[_vm._v("Attachments")])])]),_c('tbody',[_c('tr',[_c('th',[_vm._v("File Name")]),_c('th',{staticClass:"text-center"},[_vm._v("Action")])]),_vm._l((_vm.application.attachments),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(item.attachment))]),_c('td',{staticClass:"text-center"},[_c('a',{attrs:{"href":item.attachment_url,"download":"download","target":"_blank","title":"Click to download"}},[_c('i',{staticClass:"fa fa-download fa-lg",attrs:{"aria-hidden":"true"}})])])])})],2)]):_vm._e()])])],1)],1),(
          _vm.checkUsrPermissions(['CApp_all', 'CApp_verify']) &&
            _vm.application.status === 'REQUESTED'
        )?_c('div',{staticClass:"row d-print-none"},[_c('div',{staticClass:"col-md-12 text-right mb-5"},[_c('button',{staticClass:"btn btn-outline-primary btn-lg mr-4 px-5",attrs:{"type":"button"},on:{"click":function($event){_vm.showRejectModal = true}}},[_vm._v(" Reject ")]),_c('button',{staticClass:"btn btn-primary btn-lg px-5",attrs:{"type":"button"},on:{"click":function($event){return _vm.approve()}}},[_vm._v(" Approve ")])])]):_vm._e()],1),_c('CCol',{attrs:{"sm":"2"}}),_c('CCol',{attrs:{"sm":"10"}},[_c('CCard',[_c('h4',{staticClass:"logs-title"},[_vm._v("History Logs")]),_c('div',{staticClass:"table-responsive"},[(_vm.application.logs && _vm.application.logs.length)?_c('div',{staticClass:"log-list"},_vm._l((_vm.application.logs),function(log){return _c('div',{key:log.id},[_vm._v(" "+_vm._s(log.event_description)+" , by "+_vm._s(log.user_name)+" "),_c('p',[_vm._v(_vm._s(log.created_at ? _vm.dateFormat(log.created_at) : "N/A"))])])}),0):_c('div',[_c('h5',{staticClass:"text-center"},[_vm._v("Data Not Available.")])])])])],1)],1),(_vm.showRejectModal)?_c('CModal',{staticClass:"hide-footer custom-close-button",attrs:{"closeOnBackdrop":false,"title":"Reject Customer's Application","color":"primary","show":_vm.showRejectModal},on:{"update:show":function($event){_vm.showRejectModal=$event}}},[_c('RejectModal',{on:{"onApplicationRejection":_vm.dismissRejectModalModal}})],1):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-10"},[_c('h4',{staticClass:"text-uppercase"},[_vm._v("Customer's Application Details")])])}]

export { render, staticRenderFns }