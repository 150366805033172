<template>
  <div id="printArea">
    <div class="row">
      <div class="col-md-10">
        <h4 class="text-uppercase">Customer's Application Details</h4>
      </div>
      <div class="col-md-2 text-right d-print-none">
        <a
          href="javascript:void(0)"
          @click="
            $router.push('/customer-applications/' + application.id + '/edit')
          "
          v-if="
            checkUsrPermissions(['CApp_all', 'CApp_edit']) &&
              application.status !== 'APPROVED'
          "
          class="btn btn-primary mr-4"
          ><i class="fa fa-pencil" aria-hidden="true"></i> Edit
        </a>

        <a href="javascript:void(0)" class="print-btn" @click="print()"
          ><i class="fa fa-print" aria-hidden="true"></i> Print</a
        >
      </div>
    </div>

    <CRow>
      <CCol sm="2" class="pr-0">
        <CCard>
          <CRow>
            <CCol class="avatar-container">
              <img
                class="mb-2"
                name="cil-user"
                :src="
                  application.photo_url
                    ? application.photo_url
                    : getImgUrl('avatar_placeholder.png')
                "
                alt="User Logo"
              />
            </CCol>
          </CRow>
        </CCard>
      </CCol>
      <CCol sm="10">
        <CCard>
          <CRow>
            <CCol sm="12" class="text-right d-print-none">
              <!-- <CButton @click="print()" color="info" shape="pill"
                >Print</CButton
              > -->
            </CCol>
            <CCol sm="12">
              <div class="table-responsive">
                <table class="table table-borderless appl-view-tbl">
                  <thead>
                    <tr>
                      <th colspan="6">Primary Information</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Full Name:</td>
                      <td>
                        {{
                          application.salutation +
                            " " +
                            application.first_name +
                            " " +
                            application.last_name
                        }}
                      </td>
                      <td>Father's Name:</td>
                      <td>{{ application.father_name }}</td>
                      <td>Mother's Name:</td>
                      <td>{{ application.mother_name }}</td>
                    </tr>
                    <tr>
                      <td>Spouse Name:</td>
                      <td>{{ application.spouse_name }}</td>
                      <td>Mobile No:</td>
                      <td>{{ application.mobile_number }}</td>
                      <td>Email:</td>
                      <td>{{ application.email }}</td>
                    </tr>
                    <tr>
                      <td>Category:</td>
                      <td>{{ application.category }}</td>
                      <td>Connection Type:</td>
                      <td>{{ application.connection_type }}</td>
                      <td>Connection Durability:</td>
                      <td>{{ application.connection_duration }}</td>
                    </tr>
                    <tr>
                      <td>Has any Connection?</td>
                      <td>
                        {{ application.has_previous_connection ? "Yes" : "No" }}
                      </td>
                      <td>Created At</td>
                      <td>{{ dateFormat(application.created_at) }}</td>
                      <td>Account No</td>
                      <td>
                        {{ application.account_number }}
                      </td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>
                        <span
                          class="badge"
                          :class="
                            application.status === 'APPROVED'
                              ? 'badge-success'
                              : application.status === 'REJECTED'
                              ? 'badge-danger'
                              : 'badge-primary'
                          "
                          >{{ application.status }}</span
                        >
                      </td>
                      <td v-if="application.status === 'REJECTED'">
                        Reasons of Rejection
                      </td>
                      <td v-if="application.status === 'REJECTED'">
                        {{ application.rejected_reason }}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table class="table table-borderless appl-view-tbl">
                  <thead>
                    <tr>
                      <th colspan="6">Address Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Address:</td>
                      <td>{{ application.address }}</td>
                      <td>Area:</td>
                      <td>{{ application.area }}</td>
                      <td>Ward No:</td>
                      <td>{{ application.ward_number }}</td>
                    </tr>
                    <tr>
                      <td>City:</td>
                      <td>{{ application.city }}</td>
                      <td>Division:</td>
                      <td>{{ application.division }}</td>
                      <td>Country:</td>
                      <td>{{ application.country }}</td>
                    </tr>
                  </tbody>
                </table>

                <table
                  class="table table-borderless appl-view-tbl"
                  v-if="
                    application.related_fees && application.related_fees.length
                  "
                  style="max-width: 45%; margin-right: 10%; float: left;"
                >
                  <thead>
                    <tr>
                      <th colspan="6">Connection/Other Fees</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Item Name</th>
                      <th class="text-center">Fees</th>
                    </tr>
                    <tr
                      v-for="(val, index) in application.related_fees"
                      :key="index"
                    >
                      <td>{{ val.item }}</td>
                      <td class="text-center">{{ val.fee }}</td>
                    </tr>
                    <tr style="border-top: 1px solid #ccc;">
                      <th class="text-right">Total</th>
                      <th class="text-center">{{ calculateTotal() }}</th>
                    </tr>
                  </tbody>
                </table>

                <table
                  class="table table-borderless appl-view-tbl"
                  v-if="
                    application.attachments && application.attachments.length
                  "
                  style="max-width: 45%; float: left;"
                >
                  <thead>
                    <tr>
                      <th colspan="6">Attachments</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>File Name</th>
                      <th class="text-center">Action</th>
                    </tr>
                    <tr
                      v-for="(item, index) in application.attachments"
                      :key="index"
                    >
                      <td>{{ item.attachment }}</td>
                      <td class="text-center">
                        <a
                          :href="item.attachment_url"
                          download="download"
                          target="_blank"
                          title="Click to download"
                          ><i
                            class="fa fa-download fa-lg"
                            aria-hidden="true"
                          ></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CCol>
          </CRow>
        </CCard>

        <div
          class="row d-print-none"
          v-if="
            checkUsrPermissions(['CApp_all', 'CApp_verify']) &&
              application.status === 'REQUESTED'
          "
        >
          <div class="col-md-12 text-right mb-5">
            <button
              type="button"
              class="btn btn-outline-primary btn-lg mr-4 px-5"
              @click="showRejectModal = true"
            >
              Reject
            </button>
            <button
              type="button"
              class="btn btn-primary btn-lg px-5"
              @click="approve()"
            >
              Approve
            </button>
          </div>
        </div>
      </CCol>

      <CCol sm="2"></CCol>
      <CCol sm="10">
        <CCard>
          <h4 class="logs-title">History Logs</h4>
          <div class="table-responsive">
            <div
              v-if="application.logs && application.logs.length"
              class="log-list"
            >
              <div v-for="log in application.logs" :key="log.id">
                {{ log.event_description }}
                , by {{ log.user_name }}
                <p>{{ log.created_at ? dateFormat(log.created_at) : "N/A" }}</p>
              </div>
            </div>
            <div v-else>
              <h5 class="text-center">Data Not Available.</h5>
            </div>
          </div>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      :closeOnBackdrop="false"
      v-if="showRejectModal"
      title="Reject Customer's Application"
      color="primary"
      class="hide-footer custom-close-button"
      :show.sync="showRejectModal"
    >
      <RejectModal
        @onApplicationRejection="dismissRejectModalModal"
      ></RejectModal>
    </CModal>
  </div>
</template>

<script>
import RejectModal from "./modals/RejectModal.vue";
import { mapGetters } from "vuex";
export default {
  name: "CustomerAppViewComp",
  data: () => {
    return {
      printPage: false,
      showRejectModal: false,
    };
  },
  components: {
    RejectModal,
  },
  methods: {
    calculateTotal() {
      let total = 0;
      this.application.related_fees.forEach((element) => {
        total += element.fee ? parseFloat(element.fee) : 0;
      });
      this.application.total_fees = total.toFixed(2);
      return total.toFixed(2);
    },
    dateFormat(date) {
      return moment(date).format("Do MMM, YYYY h:mm a");
    },
    getImgUrl(pic) {
      return require("../../assets/images/" + pic);
    },
    dismissRejectModalModal() {
      this.showRejectModal = false;
    },
    approve() {
      this.$confirm({
        message: `Are you sure, want to approve the application?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.$store
              .dispatch("CustomerApplication/verifyApplication", {
                status: "APPROVED",
                id: this.$route.params.id,
              })
              .then(
                () => {
                  this.$store.dispatch("Settings/loading", false);
                  this.$toastr.s(
                    "Customer's application successfuly approved.",
                    "Approved"
                  );
                },
                (error) => {
                  this.$toastr.e(error.response.data.message, "Failed!");
                  this.$store.dispatch("Settings/loading", false);
                }
              );
          }
        },
      });
    },
    print() {
      this.printPage = true;
      setTimeout(() => {
        this.printSetup();
      }, 50);
    },

    printSetup() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("printArea").innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    <title>Customer Application Details</title>
    ${stylesHtml}
  </head>
  <body>
    ${prtHtml}
  </body>
</html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.addEventListener("load", () => {
        setTimeout(() => {
          WinPrint.print();
          WinPrint.close();
          this.printPage = false;
        }, 200);
      });
    },
  },
  computed: { ...mapGetters("CustomerApplication", ["application"]) },
  mounted() {
    this.$store.dispatch(
      "CustomerApplication/getApplicationInfo",
      this.$route.params.id
    );
  },
};
</script>

<style scoped>
.custom-close-button >>> button {
  color: aliceblue;
}
.hide-footer >>> footer {
  display: none;
}
.card {
  border: none;
  padding: 30px 20px;
}
tbody:before {
  content: "@";
  display: block;
  line-height: 0;
  text-indent: -99999px;
}

.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
.badge {
  padding: 5px 7px;
}
.custom-width {
  width: 100%;
}
</style>

<style lang="scss" scoped>
.avatar-container {
  width: 100%;
  padding: 4px;
  text-align: center;
  img {
    border: 1px solid #ccc;
    max-width: 100%;
  }
}
.appl-view-tbl {
  margin-bottom: 40px;
  tr {
    td {
      font-size: 14px;
      font-weight: 600;
      &:nth-child(odd) {
        color: #666;
        width: 14%;
      }
      &:nth-child(even) {
        color: #333;
        width: 19%;
      }
    }
  }
}

.logs-title {
  background: #f8f9fa;
  color: #000;
  padding: 15px;
}

.log-list {
  border: 1px solid #dadada;
  background-color: #eff0f5;
  border-radius: 4px;
  padding: 15px;
  position: relative;
  font-size: 13px;
  color: #212121;
  word-break: break-word;
  div {
    line-height: 2;
  }
  span {
    color: #9e9e9e;
  }
}

.print-btn {
  font-size: 20px;
  color: inherit;
  text-decoration: none;
  i {
    font-size: 28px;
  }
}

.customer-detail-list {
  margin-top: 5px;
  list-style-type: none;
  padding: 0;
  li {
    width: 100%;
    float: left;
    p {
      float: left;
    }
    p {
      &:first-of-type {
        width: 35%;
        max-width: 100px;
        font-weight: 600;
      }
      &:last-of-type {
        width: 65%;
      }
    }
  }
}

div {
  .row {
    .card {
      p {
        color: #000;
      }
      .row {
        p {
          color: #5e76e7;
        }
      }
    }
  }
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
  .table {
    font-size: 14px;

    thead {
      tr {
        th {
          background: #f8f9fa;
          color: #000;
        }
      }
    }
    tbody {
      font-size: 13px;
      tr {
        color: #000;
        td {
          padding: 0.75rem 0 0.75rem 0.75rem;
          button {
            font-size: 13px;
          }
        }
        // td:nth-child(1) {
        //   color: #5e76e7;
        // }
        // td:nth-child(2) {
        //   font-weight: 500 !important;
        // }
      }
    }
  }
}
</style>
